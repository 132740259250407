/* You can add global styles to this file, and also import other style files */

// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// Custom styles for this theme
@import "theme";

// Some temp fixes
//@import "fixes";

// If you want to add custom CSS you can put it here.
@import "custom";

// Examples
// We use those styles to show code examples, you should remove them in your application.
@import "examples";

@import "ngMultiSelectDropdown";

@import "ngPagination";

.table > :not(caption) > * > *{
  padding: 0.1rem;
}
.colButton{
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.card-footer{
  padding: 0%;
}

.app-default-footer.footer{
  padding-top: 0.01rem;
  padding-bottom: 0.01rem;
}

.btn-primary{
  background-color: #0f1a36;
  border-color: #0f1a36;
}

.btn-primary:hover{
  background-color: #182a57;
  border-color: #182a57;
}

.btn:disabled{
  background-color: #182a57;
  border-color: #182a57;
}

.btn-ghost-primary{
  color: #0f1a36;
}

.btn-ghost-primary:hover{
  background-color: #182a57;
  border-color: #182a57;
}

.btn-link{
  color: #0f1a36;
}

.btn-link:hover{
  color: #182a57;
}

.spinnerCol{
  color: #0f1a36;
}

.sidebar-nav .nav-link.active{
  background-color: rgb(57 76 160);
}

.sidebar-nav svg.nav-icon{
  color: #b9b6b6;
}