// Custom tooltips

.chartjs-tooltip {
  // scss-docs-start chartjs-tooltip-css-vars
  --#{$prefix}chartjs-tooltip-zindex: #{$chartjs-tooltip-zindex};
  --#{$prefix}chartjs-tooltip-padding-x: #{$chartjs-tooltip-padding-x};
  --#{$prefix}chartjs-tooltip-padding-y: #{$chartjs-tooltip-padding-y};
  --#{$prefix}chartjs-tooltip-color: #{$chartjs-tooltip-color};
  --#{$prefix}chartjs-tooltip-bg: #{$chartjs-tooltip-bg};
  --#{$prefix}chartjs-tooltip-border-radius: #{$chartjs-tooltip-border-radius};
  --#{$prefix}chartjs-tooltip-transition: #{$chartjs-tooltip-transition};
  --#{$prefix}chartjs-tooltip-header-margin: #{$chartjs-tooltip-header-margin};
  --#{$prefix}chartjs-tooltip-header-font-size: #{$chartjs-tooltip-header-font-size};
  --#{$prefix}chartjs-tooltip-header-font-weight: #{$chartjs-tooltip-header-font-weight};
  --#{$prefix}chartjs-tooltip-body-font-size: #{$chartjs-tooltip-body-font-size};
  --#{$prefix}chartjs-tooltip-body-font-weight: #{$chartjs-tooltip-body-font-weight};
  // scss-docs-end chartjs-tooltip-css-vars

  position: absolute;
  z-index: var(--#{$prefix}chartjs-tooltip-zindex);
  padding: var(--#{$prefix}chartjs-tooltip-padding-y) var(--#{$prefix}chartjs-tooltip-padding-x);
  color: var(--#{$prefix}chartjs-tooltip-color);
  pointer-events: none;
  background: var(--#{$prefix}chartjs-tooltip-bg);
  opacity: 0;
  transform: translate(-50%, 0);
  @include border-radius(var(--#{$prefix}chartjs-tooltip-border-radius));
  @include transition(var(--#{$prefix}chartjs-tooltip-transition));
}

.chartjs-tooltip-header {
  margin-bottom: var(--#{$prefix}chartjs-tooltip-header-margin);
}

.chartjs-tooltip-header-item {
  font-size: var(--#{$prefix}chartjs-tooltip-header-font-size);
  font-weight: var(--#{$prefix}chartjs-tooltip-header-font-weight);
}

.chartjs-tooltip-body-item {
  align-items: center;
  font-size: var(--#{$prefix}chartjs-tooltip-body-font-size);
  font-weight: var(--#{$prefix}chartjs-tooltip-body-font-weight);
  white-space: nowrap;
}
